import React from 'react';
import {
  AppStore
} from '../../stores';

const routeParams = window.location.pathname.split('/');

const loopId = routeParams[1];
let screenId = routeParams[2] && routeParams[2].toLowerCase() !== 'preview' ? routeParams[2] : null;
let isPreview = false;

if (routeParams.map(param => param.toLowerCase()).includes('preview')) {
  isPreview = true;
}

export default React.createContext({
  appStore: new AppStore(
    loopId !== undefined ? loopId : '',
    screenId !== undefined ? screenId : '',
    isPreview
  ),
});