import Api from '../Api';
import axios from 'axios';

/**
 * @return {{{"status":"OK","data":{"needs_refresh":false}}}
 */
export const getScreenRefresh = async (loopId, screenId, isPreview) => {
    if (loopId.indexOf('demo-') === 0) {
        const response = await axios.get(`/fixtures/screen-refresh.json`);
        const needsRefresh = await response.data.needs_refresh;
        return !!needsRefresh;
    }

    const previewParam = isPreview ? '&preview=1' : '';
    const screenIdParam = screenId ? `&screen_id=${screenId}` : '';
    const response = await Api.get(`/site/screen-refresh?loop_id=${loopId}${screenIdParam}${previewParam}`);
        console.log(response.data.data);
    return await !!response.data.data.needs_refresh;
};