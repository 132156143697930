import Api from '../Api';
import axios from 'axios';
import { compatibleApiVersion } from '../../package.json';

/**
 * @return {{
 *  next_mandatory_refresh: number, // utc timestamp.
 *  playlists: {
 *      zone_id: number,
 *      resume_playing_from: number,
 *      widgets: {
 *          duration: number, // lifetime of widget in milliseconds.
 *          type: string, // unique slug of widget type.
 *          settings: object // widget specific settings.
 *      }[]
 *  }[]}
 * }
 */
export const getPlaylist = async (loopId, screenId, isPreview) => {
    if (loopId.indexOf('demo-') === 0) {
        const response = await axios.get(`/fixtures/${loopId}.json`);
        const playlist = await response.data.playlist;
        playlist.version = compatibleApiVersion;
        return playlist;
    }

    const previewParam = isPreview ? '&preview=1' : '';
    const screenIdParam = screenId ? `&screen_id=${screenId}` : '';
    const response = await Api.get(`/site/screen-content?loop_id=${loopId}${screenIdParam}${previewParam}`);
    return await response.data;
};