import React from "react";
import PropTypes from "prop-types";
import { Player, ControlBar } from "video-react";
import "video-react/dist/video-react.css";
import "./video-react.css";

import styles from "./VideoWidgetView.module.less";
import WidgetViewProvider from "../../../WidgetViewProvider";

const VideoWidgetView = ({ url, muted, ...props }) => (
  <div className={styles.container} {...props}>
    <Player
      preload="auto"
      width="100%"
      height="100%"
      muted
      autoPlay
      aspectRatio="16:9"
      src={url}
    >
      <ControlBar disableCompletely />
    </Player>
  </div>
);

VideoWidgetView.propTypes = {
  url: PropTypes.string.isRequired,
};

WidgetViewProvider.registerWidgetView(
  {
    widget: "video",
    default: true,
}, VideoWidgetView);
